<template>
	<div>
        <Dialog style="background: #727376;" :header="$t('Registrarse')" v-model:visible="displayRegister" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true">
            <div class="p-fluid">
				<img  width="150" src="images/logo2.png" style="margin-left: -30px;">
                <strong>{{$t('MensRegistro1')}}</strong>
                <div class="p-text-center"><strong v-if="!verificacion">{{$t('MensRegistro2')}}</strong></div>
                    <div class="p-field p-grid">
                    <div class="p-col-12 p-md-12" v-if="!verificacion">
						<label>{{$t('Correo')}}</label>
						<InputText v-model="correo" type="email" autofocus/>
					</div>
                    <div class="p-col-12 p-md-12" v-if="verificacion">
						<label>{{$t('Correo')}}</label>
						<InputText v-model="correo" type="email" :disabled="verificacion == true" autofocus/>
					</div>
                    <div class="p-col-12 p-md-8" v-if="verificacion">
                        <label class="p-col-12 p-md-2">{{$t('Código Validación')}}</label>
                        <InputText v-model="codigo" type="text" @keyup.enter="Verificar" :disabled="validar == true"/>
                    </div>
                    <div class="p-col-12 p-md-8" v-if="verificacion">
                        {{$t('Código Validación Mens')}}
                    </div>
					<div class="p-col-12 p-md-12" v-if="verificacion">
						<label>{{$t('Nombre Completo')}}</label>
						<InputText v-model="nombre" type="text" id="nombre" />
					</div>
					<div class="p-col-12 p-md-6" v-if="verificacion">
						<label>{{$t('Contraseña')}}</label>
                        <InputText v-model="clave" type="password" id="clave2" />
					</div>
					<div class="p-col-12 p-md-6" v-if="verificacion">
						<label>{{$t('Confirmar Contraseña')}}</label>
                        <InputText v-model="claveconf" type="password" id="clave3" />
					</div>
                    <div class="p-col-12 p-md-12" v-if="verificacion">
						{{$t('Contraseña Format')}}
					</div>
                </div>	
			</div>	
            <template #footer>
                <Button :label="$t('Aceptar')" icon="pi pi-check" class="p-button-secondary" @click="Register" autofocus  v-if="verificacion"/>
                <Button :label="$t('Validar Correo')" icon="pi pi-check" class="p-button-secondary" @click="Validacion" autofocus  v-else/>
                <Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="close"/>
            </template>
        </Dialog>
	</div>
</template>

<script>
import API from "../service/ApiConsulta";
import Crypto from "crypto-js";
import { useI18nPlugin } from '@unify/vuex-i18n';

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		}
	},
    data() {
        return {
            displayRegister: false,
            correo: null,
            clave: null,
			nombre: null,
            claveconf: null,
            size: '40vw',
            verificacion: false,
            codigo: null,
            verificacioncodigo: null,
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
        this.i18n = useI18nPlugin();
    },
    methods: {
        limpiar() {
            this.correo = null;
            this.nombre = null;
            this.clave = null;
            this.claveconf = null;
            this.verificacion = false;
            this.verificacioncodigo = null;
            this.codigo = null;
       },
        close() {
            this.displayRegister = false;
            this.limpiar();
            this.$emit('close-register', event);
        },
        Validacion() {
            if (this.correo == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Correo'), life: 10000});
            } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.correo)) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Correo'), life: 10000});
            } else {
                this.$store.commit('Loading');
                const Consulta = new API('Seguridad'); 
                Consulta.Procesar('EnviarCodigo',{
                    correo: this.correo
                }).then(response => {
                    //this.$store.state.error = response;
                    if(response.codigo){
                        this.verificacioncodigo = response.codigo;
                        this.verificacion = true;
                        this.$toast.add({severity:'success', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                    } else {
                        this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                    } 
                    this.$store.commit('Loading');                  
                });         
            } 
        },
        Register() {
            if (this.nombre == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Nombre'), life: 10000});
            } else if (this.clave == null) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Contraseña'), life: 10000});
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_.!@#$%^&*])(?=.{8,})/.test(this.clave)) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Contraseña'), life: 10000});
            } else if (this.clave != this.claveconf) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Confirmación'), life: 10000});
            } else if (this.codigo != this.verificacioncodigo) {
                this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t('Error en Código'), life: 10000});
            } else {
                this.$store.commit('Loading');
                const cifrado = Crypto.HmacSHA1(this.clave, "btcoin").toString();
                const Consulta = new API('Seguridad');
                Consulta.Procesar('Registrar',{
                    nombre: this.nombre,
                    correo: this.correo,
                    clave: cifrado,
                    leng: this.i18n.locale(),
                }).then(response => {
                    //this.$store.state.error = response;	
                    if(response.error){
                        this.$toast.add({severity:'error', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                        this.$store.commit('Loading');
                    } else {
                        this.$toast.add({severity:'success', summary: this.$t('Mensaje'), detail: this.$t(response.mensaje), life: 10000});
                        this.$store.commit('Loading');
                        this.close();
                    }                
                });
                this.limpiar();
            } 
        },
        onMenuToggle(event) {
            this.$emit('aprob-login', event);
            this.$emit('close-login', event);
        },	

	},
    watch: {
		display(newValue) {
			this.displayRegister = newValue;
		}
	},


}
</script>