import API from './API';

class APIs {

    constructor(modulo=null) {
        this.modulo = modulo;
        this.token = localStorage.getItem('Token');
    }
    Estatus() {
        return API({
            modulo:  this.modulo
        }).then(res => res.data);
    }
    Ini(funcion) {
        return API({
            modulo:  this.modulo,
            funcion:  funcion,
            token:  this.token
        }).then(res => res.data);
	}
    Procesar(funcion,array) {
        return API({
            modulo:  this.modulo,
            funcion:  funcion,
            token:  this.token,
            array:  array
        }).then(res => res.data);
    }

    fstring(fech){
        if(fech){
            const [year, month, day] = fech.split('-');
            return day+'/'+month+'/'+year;    
        } else {
            return null;
        }
    }

    fdate(dato){
        if(dato){
            const [day, month, year] = dato.split('/');
            return new Date(+year, +month - 1, +day);
        } else {
            return null;
        }
    }

}

export default APIs;