import jsPDF from 'jspdf';
import 'jspdf-autotable';
import QR from "qr-encode";
import url from "./_URL";

var pdf = new jsPDF();
var logo = "images/logo.jpg";

class PDFs {

    Plantilla(){
        pdf.addImage(logo, 'JPEG', 65, 10, 90, 25);
        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");
        pdf.text("SaBeé Insurance", 10, 35);
        pdf.setFontSize(8);
        pdf.setFont("italic","normal");
        pdf.text("Do it Secure, Do it SaBee", 10, 38);
        pdf.text("World Trade Center, Piscadera Bay Z/N, Willemstad – Curaçao", 10, 41);
        pdf.text("Phone +59996761002 - +59996905811", 10, 44);
        pdf.text("Email: sales@internationalinsurancenv.com", 10, 47);
        pdf.text(100, 261, 'SaBee Insurance. – Registration Number 102716', 'center');
        pdf.setFont("italic","normal");
        pdf.text(100, 264, 'World Trade Center, Piscadera Bay Z/N, Willemstad – Curaçao', 'center');
        pdf.text(100, 267, 'Phone +59996761002 - +59996905811', 'center');
        pdf.text(100, 270, 'Website: www.sabeeinsurance.com   e-mail: cvdestefano@internationalinsurancenv.com', 'center');
    }

    Plantilla2(){
        pdf.addImage(logo, 'JPEG', 65, 10, 90, 25);
        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");
        pdf.text("SaBeé Insurance", 10, 35);
        pdf.setFontSize(8);
        pdf.setFont("italic","normal");
        pdf.text("Do it Secure, Do it SaBee", 10, 38);
        pdf.text("World Trade Center, Piscadera Bay Z/N, Willemstad – Curaçao", 10, 41);
        pdf.text("Phone +59996761002 - +59996905811", 10, 44);
        pdf.text("Email: sales@internationalinsurancenv.com", 10, 47);
        pdf.text(100, 261, 'SaBee Insurance. – Registration Number 102716', 'center');
        pdf.setFont("italic","normal");
        pdf.text(100, 264, 'Website: www.sabeeinsurance.com', 'center');
    }


    Tabla(margen,altura,titulos,filas){
        pdf.autoTable({
            head: titulos,
            body: filas,
            startY: altura,
            margin: margen,
            headerStyles: {fillColor: [114, 115, 118]}, 
            columnStyles: {text: {columnWidth: 'auto'}},             
            styles: {
                //font: 'courier',
                lineColor: [114, 115, 118],
                lineWidth: 0.1
            },
        });
    }

    Out(){
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(pdf.output("blob"), "Name.pdf");
        } else {
            //pdf.autoPrint();
            window.open(
                URL.createObjectURL(pdf.output("blob")),
                "_blank",
                "height=650,width=500,scrollbars=yes,location=yes"
            );
            setTimeout(() => {    
                window.URL.revokeObjectURL(pdf.output("bloburl"));
            }, 100);
        }
        pdf = new jsPDF();      
    }

    moneda(number){
        return new Intl.NumberFormat("de-DE", {style: "currency", currency: "ANG"}).format(parseFloat(number).toFixed(2));
    }
    
    Cotizacion(caso,data,duracion,cuotas,monto) {
        this.Plantilla2();     
        pdf.setFontSize(10);
        pdf.setFont("italic","normal");
        pdf.text("Quote: "+data.id, 20, 90);

        pdf.text("Date: "+data.fecha, 160, 90);
        pdf.text("Valid until: "+data.fecha, 160, 100);
        pdf.text("Delivered to: "+data.nombre, 20, 110);

        if(caso==2){
            pdf.text(data.tipo_name, 20, 115);
            const head = [['Brand', 'Model', 'Year','Plate Number','Duration','Quote']];
            const body = [
              [data.marca,data.modelo,data.veh_ano,data.veh_placa,duracion[data.duracion].name,cuotas[data.cuotas].valor]
            ];
            this.Tabla(20,120,head,body);         
        } else {
            const head = [['Policy','Duration','Quote']];
            const body = [
              [data.evaluacion.tipopoliza_name,duracion[data.duracion].name,cuotas[data.cuotas].valor]
            ];
            this.Tabla(20,120,head,body);         

        }

        if (data.cuotas>0){
            pdf.text("Quote: "+this.moneda(monto/cuotas[data.cuotas].valor), 150, 150);
        }

        pdf.setFontSize(9);
        pdf.text(105, 210, 'Important: By paying this premium, the applicant accepts  the policy terms and conditions of this policy. See terms and conditions.', 'center');

        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");
        pdf.text("Total: "+this.moneda(monto), 150, 170);

        var mensa1 = "(1) This document is a quotation; in no time does it represent a commitment from SaBee Insurance (aka International Insurance Company) even if the reported amount has been paid. It only represents a commitment when the insurance certificate of the corresponding quote has been delivered.";
        var mensa2 = "(2) Certificate issuance subject to a review of the client’s claim history.";
        var mensa3 = "Make payments to our account (International Insurance Company) at Banco Di Caribe #30016701 or in cash/credit/debit at our offices located at the World Trade Center Curacao";

        const body2 = [[mensa1],[mensa2],[mensa3]];

        pdf.autoTable({
            body: body2,
            startY: 220,
            margin: 20,
            columnStyles: {text: {columnWidth: 'auto'}},             
        });
        this.Out();     
    }

    Certificado(data) {
        pdf = new jsPDF('l', 'mm', [210, 145]);
        
        var dataURI = QR(url.val+data.id, { type: 6, size: 5, level: "Q" ,
        image: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",});
        var img = new Image();
        img.src = dataURI;
        var qr = img.src;

        pdf.addImage(qr, 'JPEG', 160, 10, 40, 40);
        pdf.setFontSize(10);
        pdf.setFont("italic","bold");
        pdf.text(180, 8, "Scan QR code for VALIDITY", 'center');

        this.Plantilla();     
        pdf.setFontSize(12);
        pdf.setFont("italic","bold");
        pdf.text(100, 56, "CERTIFICATE OF INSURANCE: "+data.codigo, 'center');

        pdf.setFontSize(10);
        pdf.rect(10, 60, 190, 75); 

        pdf.setFont("italic","normal");
        pdf.text("Doc Id:", 15, 70);
        pdf.rect(33, 65, 35, 7); 
        pdf.text("Policy Holder:", 70, 70);
        pdf.rect(95, 65, 100, 7); 
        pdf.text("Client Nr:", 15, 80);
        pdf.rect(33, 75, 35, 7); 
        pdf.text("Policy:", 70, 80);
        pdf.rect(95, 75, 100, 7); 
        pdf.text("Duration:", 15, 90);
        pdf.rect(33, 85, 35, 7); 
        pdf.text("Period:", 70, 90);
        pdf.rect(95, 85, 100, 7); 

        pdf.text("What to do in case of accident? Call 199 (CRS)", 15, 120);
        pdf.text("Claim Reminder: Annual Premiums must be paid in full for your claims to be processed", 15, 125);

        pdf.setFontSize(8);
        pdf.text("Client has accepted policy conditions.", 15, 130);
        pdf.text("See terms & conditions", 20, 133);

        if(data.tipo==1){
            pdf.setFont("italic","bold");
            pdf.text(data.incendio.code, 35, 70);        
            pdf.text(data.incendio.nombre, 100, 70);
            pdf.text('# '+data.usuario.toString().padStart(7,'0'), 35, 80);
            pdf.text(data.incendio.tipopoliza_name, 100, 80);
            pdf.text(data.duracion_name, 35, 90);
            pdf.text(data.activacion+' - '+data.vence, 100, 90);
            pdf.setFontSize(14);
            pdf.text("FIRE INSURANCE", 10, 22);
        }

        if(data.tipo==2){
            pdf.setFont("italic","bold");
            pdf.text(data.vehiculo.code, 35, 70);        
            pdf.text(data.vehiculo.nombre, 100, 70);
            pdf.text('# '+data.usuario.toString().padStart(7,'0'), 35, 80);
            pdf.text(data.vehiculo.poliza_name, 100, 80);
            pdf.text(data.duracion_name, 35, 90);
            pdf.text(data.activacion+' - '+data.vence, 100, 90);
            pdf.setFontSize(14);
            pdf.text("VEHICLE INSURANCE", 10, 22);

            pdf.setFontSize(10);
            pdf.setFont("italic","normal");
            pdf.text("Plate Nr:", 15, 100);
            pdf.rect(33, 95, 70, 7); 
            pdf.text("Chassis :", 105, 100);
            pdf.rect(125, 95, 70, 7); 
            pdf.text("Brand:", 15, 110);
            pdf.rect(33, 105, 45, 7); 
            pdf.text("Model:", 80, 110);
            pdf.rect(95, 105, 45, 7); 
            pdf.text("Year:", 145, 110);
            pdf.rect(155, 105, 40, 7); 

            pdf.setFont("italic","bold");
            pdf.text(data.vehiculo.veh_placa, 35, 100);        
            pdf.text(data.vehiculo.veh_chasis, 130, 100);
            pdf.text(data.vehiculo.marca, 35, 110);        
            pdf.text(data.vehiculo.modelo, 100, 110);
            pdf.text(''+data.vehiculo.veh_ano, 160, 110);

        }

        if(data.tipo==3){
            pdf.setFont("italic","bold");
            pdf.text(data.salud.code, 35, 70);        
            pdf.text(data.salud.nombre, 100, 70);
            pdf.text('# '+data.usuario.toString().padStart(7,'0'), 35, 80);
            pdf.text(data.salud.tipopoliza_name, 100, 80);
            pdf.text(data.duracion_name, 35, 90);
            pdf.text(data.activacion+' - '+data.vence, 100, 90);
            pdf.setFontSize(14);
            pdf.text("HEALTH INSURANCE", 10, 22);
        }

        if(data.tipo==4){
            pdf.setFont("italic","bold");
            pdf.text(data.proyecto.code, 35, 70);        
            pdf.text(data.proyecto.nombre, 100, 70);
            pdf.text('# '+data.usuario.toString().padStart(7,'0'), 35, 80);
            pdf.text(data.proyecto.tipopoliza_name, 100, 80);
            pdf.text(data.duracion_name, 35, 90);
            pdf.text(data.activacion+' - '+data.vence, 100, 90);
            pdf.setFontSize(14);
            pdf.text("PROJECT INSURANCE", 10, 22);
        }
        
        this.Out();     
    }

    Factura(data) {
        this.Plantilla();     

        pdf.setFontSize(14);
        pdf.setFont("italic","bold");
        pdf.text(100, 90, 'RECEIPT # '+data.id, 'center');
        pdf.setFont("italic","normal");
        pdf.setFontSize(12);
        pdf.text("Date: "+data.fechad, 170, 90);

        pdf.rect(15, 103, 180, 40); 
        pdf.setFont("italic","normal");
        pdf.text("Name:", 20, 120);
        pdf.text("ID:", 140, 120);
        pdf.text("Email:", 20, 130);
        pdf.text("Phone:", 140, 130);
        
        pdf.setFont("italic","bold");
        pdf.text("Client", 20, 110);
        pdf.text(data.cliente.nombre, 50, 120);
        pdf.text(data.cliente.code, 160, 120);
        pdf.text(data.cliente.pcorreo, 50, 130);
        pdf.text(data.cliente.celular, 160, 130);

        var deudas = data.deudas;
        const head = [['Nº','Policies #','Description', 'Paid']];
        var body = [];
        for (var i = 0; i < deudas.length; i++) {
			body.push([
                i+1,
                data.cliente.codigo,
                'Policy '+deudas[i].name,
                this.moneda(deudas[i].monto),
            ]);
        }
        this.Tabla(15,150,head,body);     
     
        pdf.text("Important:", 15, 245);
        pdf.text("(1) Certificate issued in a separate document ", 15, 250);
        pdf.text("(2) The client is responsible for reviewing the policy conditions ", 15, 255);



        pdf.setFontSize(16);
        pdf.setFont("italic","bold");
        
        
        this.Out();     
    }

    Aceptacion(dat,data,opc) {
        //pdf.addImage("images/qr-code.jpg", 'JPEG', 165, 50, 30, 30);
        this.Plantilla();     
        pdf.setFontSize(12);

        pdf.setFont("italic","bold");
        pdf.text("Proof of Compensation /", 20, 85);
        pdf.text("Compensation Agreement", 20, 90);

        pdf.text("Willemstad", 130, 90);

        pdf.rect(15, 93, 180, 30); 
        pdf.setFont("italic","normal");
        pdf.text("Undersigned:", 20, 100);
        pdf.text("Full name:", 20, 110);        
        pdf.text("I.D. Number:", 120, 110);
        pdf.text("Address:", 20, 120);
        pdf.text("Claim Number:", 120, 120);

        pdf.text(data.nombre, 60, 110);
        pdf.text(''+data.code, 160, 110);
        if (opc==1){pdf.text(dat.incendio.localidadn, 60, 120);}
        if (opc==2){pdf.text(dat.vehiculo.localidadn, 60, 120);}
        if (opc==3){pdf.text(dat.salud.localidadn, 60, 120);}
        if (opc==4){pdf.text(dat.proyecto.localidadn, 60, 120);}
        pdf.text(''+data.claim, 160, 120);

        var parrafo0 = "The beneficiary of the claim number "+data.claim+parrafo1;
        var parrafo1 = " declares that they have agreed with  International Insurance Company N.V. the amount of Naf "+data.total;
        var parrafo2 = " against final discharge for all injuries suffered as a result of the accident covered under the policy No ______________";
        var parrafo3 = " took place on ___________ (date), _______ (year)  in ________________________ (accident address). "
        var parrafo4 = "The payment will be received via online transference. The signature of this document is necessary to initiate the compensation process. The claim will be automatically closed once the transfer of the amount referred in this agreement is executed in the beneficiary’s account."

        pdf.text(parrafo0+parrafo1+parrafo2+parrafo3+parrafo4, 
            20, 130, {align: "justify", lineHeightFactor: 1.5, maxWidth: 170 });
        
        var parrafo5 = "The parties declare that through this agreement they have made a general and final settlement, whereby the parties mutually grant each other full and final discharge regarding the claim as soon as this agreement has been fully performed.";
        var parrafo6 = "The parties waive any right to dissolve or annul the agreement, whereby the parties declare that they understand that after signing this settlement agreement, this agreement can never be revoked, not even in the event of favorable or unfavorable changing circumstances with regard to another - or all parties.";
        var parrafo7 = "The parties declare to each other - except by virtue of the provisions of this agreement - that they no longer have anything to claim from each other.";
        var parrafo8 = "This agreement shall be governed by the law of Curacao to the exclusion of any other legal system, while any dispute arising from this agreement will be exclusively submitted to the Court in Curaçao, Netherlands Antilles.";
        pdf.text(parrafo5, 20, 175, {align: "justify", lineHeightFactor: 1.5, maxWidth: 170 });
        pdf.text(parrafo6, 20, 195, {align: "justify", lineHeightFactor: 1.5, maxWidth: 170 });
        pdf.text(parrafo7, 20, 222, {align: "justify", lineHeightFactor: 1.5, maxWidth: 170 });
        pdf.text(parrafo8, 20, 235, {align: "justify", lineHeightFactor: 1.5, maxWidth: 170 });
        pdf.text('For receipt:', 20, 255);
        pdf.text('Name ____________________________', 20, 265);
        pdf.text('Signature _______________________', 20, 275);

        this.Out();     
    }





}


export default PDFs;



//export default exports;