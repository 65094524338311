<template>
	<div class="layout-top">

        <div class="layout-sup">

            <button class="p-link layout-logo">
                <router-link to="/">
                    <img alt="logo" src="images/home.webp" width="240" height="60">
                </router-link>
            </button>
            <div class="layout-top-icons">
                <button class="p-link" style="text-align: center;" @click="Simulador">
                    <span class="layout-top-icon bi bi-calculator"></span><br>
                    <span class="ocultar">{{$t('Obtener cotización')}}</span>
                </button>
                <button class="p-link" style="text-align: center;" @click="Logout" v-if="this.$store.state.authenticated">
                    <span class="layout-top-icon pi pi-sign-out"></span><br>
                    <span class="ocultar">{{$t('Salir')}}</span>
                </button>
                <button class="p-link" style="text-align: center;" @click="openLogin" v-if="!this.$store.state.authenticated">
                    <span class="layout-top-icon pi pi-sign-in"></span><br>
                    <span class="ocultar">{{$t('Ingresar')}}</span>
                </button>
                <button class="p-link" style="text-align: center;" @click="openRegister" v-if="!this.$store.state.authenticated">
                    <span class="layout-top-icon pi pi-user-plus"></span><br>
                    <span class="ocultar">{{$t('Registrarse')}}</span>
                </button>
            </div>

            <div class="layout-top-icons">
                <Menu/>
            </div>
        
        </div>
        
        <div class="layout-inf">

            <button class="p-link layout-menu-button" @click="onMenuToggle" v-if="this.$store.state.authenticated">
                <span class="pi pi-bars"></span>
            </button>
            <button class="p-link layout-menu-button" @click="lenguajeChange">
                <img :src="'images/' + i18n.locale() + '.webp'"  width="20" height="20" style="margin-left:5px;margin-right:5px"/>
            </button>   
            <a href="https://www.instagram.com/sabee_insurance/" target="_blank" style="height:20px;margin-left:5px;margin-right:5px" class="p-link layout-menu-button">
                    <i class="bi bi-instagram" style="fontSize: 1.5rem"></i>
            </a>
            <div class="layout-topbar-menu" >
                <button class="p-link">
                    <span class="layout-topbar-icon pi pi-home"></span>
                </button>
                <button class="p-link">
                    <span class="layout-topbar-icon pi pi-globe"></span>
                </button>
                <button class="p-link">
                    <span class="layout-topbar-icon pi pi-briefcase"></span>
                </button>
                <button class="p-link">
                    <span class="layout-topbar-icon pi pi-thumbs-up"></span>
                </button>
                <button class="p-link">
                    <span class="layout-topbar-icon pi pi-comments"></span>
                </button>
            </div>

        </div>

        <Login :display="displayLogin" @aprob-login="onMenuToggle" @close-login="close"/>
        <Register :display="displayRegister" @aprob-login="onMenuToggle"  @close-register="close"/>

	</div>
</template>

<script>
import Menu from './components/Menu.vue';
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import { useI18nPlugin } from '@unify/vuex-i18n';
import PDF from "./service/PDF";


export default {
	components: {
        'Menu': Menu,
        'Login': Login,
        'Register': Register
    },
    data() {
        return {
			displayLogin: false,
            displayRegister: false,
            i18n: null,
			english: false,
			menutop: []
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
        this.i18n = useI18nPlugin();
		if (localStorage.getItem('leng')){
            this.i18n.set(localStorage.getItem('leng'));  
        }
		this.$store.commit('Menu');
    },
    methods: {
        Ver(){
            const doc = new PDF();
            doc.Cotizacion();
        },

        lenguajeChange() {
            if(this.i18n.locale() == 'en') {
                this.i18n.set('es');
                localStorage.setItem('leng','es');
            } else {
                this.i18n.set('en');
                localStorage.setItem('leng','en');
            }
        },
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
        openLogin() {
            this.$store.commit('Mobile');
            this.displayRegister = false;
            this.displayLogin = true;
        },
        openRegister() {
            this.$store.commit('Mobile');
            this.displayLogin = false;
            this.displayRegister = true;
        },
        close() {
            this.displayLogin = false;
            this.displayRegister = false;
            this.limpiar();
        },
        Simulador() {
            this.$router.push({ path: '/simulator' });
        },
        Logout() {
            this.$store.commit('Logout');
            this.onMenuToggle();
            this.$router.push({ path: '/' });
            this.$router.go(0);
        }

		
	}

}
</script>